.footer-container {
  background-color: #303030;
  color: aliceblue;
  margin-top: 50px;
}
.footer-para {
  font-size: 14px;
}
@media (max-width: 900px) {
  /*Mobile View */
  .footer-logo {
    text-align: center;
    /* height: 500px; */
  }
  .footer-container {
    padding: 5px;
  }
}
