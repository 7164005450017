html {
  scroll-behavior: smooth;
}
/* homepage */
.dashboard {
  width: 100%;
  /* height: 100vh; */
  padding-top: 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./Assets/home.jpg");

  opacity: 1;
  background-size: cover;
  background-attachment: fixed;
}

/* Navbar */
.navbar-container {
  /* position: fixed; */
}
.divider {
  width: 100%;
}
.header-divider {
  width: 129px;
  height: 3px;
  background-color: #36bb86;
  margin-top: 11px;
  margin-bottom: 50px;
}

/* homepage */
.dashboardContent {
  text-align: left;
  margin-top: 100px;
  margin-left: 145px;
  color: #ffff;
  font-weight: 900;
  font-style: Roboto;
  font-size: 57px;
  line-height: 1.2em;
}
.book-an-appoinmentbutton-dash {
  margin-left: 145px;
  margin-top: -40px;
}
.review {
  color: #fff;
  border: 3px solid #36bb86;
  box-sizing: border-box;
  border-radius: 18px;
  padding: 6px;

  background-color: #36bb86;
}
.review-img {
  width: 40px;
}
.review-container {
  width: 100px;
  text-align: center;
  padding-right: 20px;
}
.review-content-header {
  font-weight: 400;
  font-size: 20px;
}
/* WelcomeContent */
.welcome-container {
  background-image: url("./Assets/background.jpg");
  background-size: cover;
  background-attachment: fixed;
  height: 400px;
}
.welcome-header {
  margin: 0px;
  padding-top: 50px;
  text-align: center;
  font-size: 44px;
  color: #303030;
  font-weight: 700;
}
.welcome-para {
  text-align: center;
  font-size: 22px;
  color: #303030;
  font-weight: 400;
  padding: 30px;
}
.whoweare-div {
  padding-top: 50px;
  background-color: #f2fff9;
}
.whoweareimg {
  /* width: 410px; */
  /* height: 400px; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.whychooseus-div {
  padding-top: 50px;
  background-color: #f2fff9;
}
.why-choose-us-img {
  width: 40px;
  padding-top: 20px;
  padding-left: 7px;
}
.one-container-why-choose-us:hover {
  transform: scale(1.05);
}
.one-container-why-choose-us {
  transition: transform 0.1s; /* Animation */
  background: #ffffff;
  box-shadow: 10px 14px 44px rgba(0, 0, 0, 0.15);
  min-height: 170px;
  border-radius: 10px;
  padding: 15 px;
}
.ourservices-one-img {
  transition: transform 0.1s; /* Animation */
  border-radius: 10px;
}
.ourservices-one-img:hover {
  transform: scale(1.05);
}
.ourprojects-one-img {
  border-radius: 5px;
  width: 370px;
  height: 300px;
}
/* for phone call  desktop */
.phonecall {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 220px;
  right: 40px;
  background-color: #2553d3;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 40px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 55px;
  height: 55px;
  bottom: 150px;
  right: 40px;

  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}
@media (max-width: 1000px) {
  .whatsapp-icon {
    margin-top: 10px;
  }
  .phone-icon {
    margin-top: 8px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 160px;
    right: 10px;
    font-size: 22px;
  }
  .phonecall {
    width: 40px;
    height: 40px;
    bottom: 208px;
    right: 10px;
    font-size: 22px;
  }
  .ourprojects-one-img {
    width: 100%;
  }
  .whoweare-div {
    padding-top: 10px;
  }
  .whoweareimg {
    width: 250px;
  }
  .header-divider {
    margin-bottom: 27px;
  }
  .welcome-container {
    height: 330px;
  }
  .welcome-header {
    padding-top: 32px;
    font-size: 22px;
  }
  .welcome-para {
    font-size: 15px;
    padding: 17px;
  }
  .review-container {
    display: none;
  }
  .dashboardContent {
    font-size: 30px;
    text-align: center;
    margin-top: 125px;
    margin-left: 0px;
  }
  .book-an-appoinmentbutton-dash {
    text-align: center;
    margin: 0px;
  }
  .dashboard {
    height: 100vh;
  }
}
