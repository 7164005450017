.getin-touch-container {
  text-align: center;
  background-color: #f2fff9;
}
.touch {
  background: #36bb86;
  box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  font-weight: 400;
  padding: 30px;
  padding-top: 10px;
  padding-bottom: 40px;
  color: aliceblue;
}
.getin-head {
  font-weight: 700;
  font-size: 32px;
}

@media (max-width: 1000px) {
  .touch {
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 50px;
    margin: 10px;
  }
  .getin-touch-container {
  }
  .getin-head {
    font-size: 22px;
  }
  .touch {
    box-shadow: none;
  }
}
